<template lang="pug">
v-container
    v-btn.white--text(color='brand', @click='newDepDialog = true') Add New
    v-data-table(:loading='fetchingDeps', :items='allDeps', :headers='headers', height='75vh')
        template(v-slot:item.delete='{item}')
            v-btn(icon, @click='deleteDependency(item)', small)
                v-icon delete
            span {{ item.custom_description }}
    v-dialog(v-model='newDepDialog', max-width='500px')
        v-card(light)
            v-card-title New Part Dependency
            v-card-text
                v-form( ref="form", v-model="isValid")
                    v-row 
                        v-col 
                            v-autocomplete(label='Product ID', v-model='newDependency.product_id', color='brand', chips, :items='allParts', item-text='product_id', item-value='product_id', :rules='partRules')
                        v-col
                            v-autocomplete(label='Required Product ID', v-model='newDependency.depProduct_id', color='brand', chips, :items='allParts', item-text='product_id', item-value='product_id', :rules='depRules')
                    v-row
                        v-col
                            v-checkbox(label='Force Require', v-model='newDependency.force', color='brand')
            v-card-actions
                v-spacer
                v-btn(text, @click='newDepDialog = false') Cancel
                v-btn.white--text(color='brand', :disabled='!isValid', @click='addNewDep') Save
</template>
<script>
import {debounce} from 'lodash';

export default {
    props: {},
    data () {
        return {
            newDepDialog: false,
            itemsPerPage: 20,
            currentPageNumber: 1,
            searchString: '',
            totalItems: 0,
            allParts: [],
            allDeps: [],
            headers: [
                {
                    text: 'Product ID',
                    value: 'product_id'
                },
                {
                    text: 'Required Product ID',
                    value: 'depProduct_id'
                },
                {
                    text: 'Force Require',
                    value: 'force'
                },
                {
                    text: '',
                    value: 'delete',
                    sortable: false
                }
            ],
            newDependency: {
                product_id: '',
                depProduct_ID: '',
                force: false
            },
            selectedDep: null,
            fetchingDeps: false,
            isValid: false,
            partRules: [
                value => !!value || 'Required',
            ],
            depRules: [
                value => !!value || 'Required',
                value => {
                    return !this.dependencyExists(this.newDependency) || 'This dependency already exists';
                },
                value => {
                    return value !== this.newDependency.product_id || 'A part cannot depend on itself, unlike a person';
                },
            ],
        };
    },
    computed:{},
    methods: {
        refreshData () {
            this.fetchingDeps = true;
            this.socketEmit('getAllParts', null, (results) => {
                this.allParts = results;
            });
            this.socketEmit('getPartDependencies', null, (results) => {
                this.allDeps = results.filter(dep => dep.active);
            });
            this.fetchingDeps = false;
        },
        
        addNewDep () {
            this.socketEmit('addPartDependency', this.newDependency, (results) => {
                this.refreshData();
                this.newDepDialog = false;
            });
        },
        selectPart (dep) {
            this.selectedDep = dep;
        },
        dependencyExists (dep) {
            return this.allDeps.some((d) => {
                return d.product_id === dep.product_id && d.depProduct_id === dep.depProduct_id;
            });
        },
        deleteDependency (dep) {
            this.socketEmit('deletePartDependency', dep, (results) => {
                this.refreshData();
            });
        }
    },
    watch: {
    },
    mounted () {
        window.satPartDeps = this;
        this.refreshData();
    }
};
</script>
